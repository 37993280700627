import { useMemo } from "react"
import { useSimpleApiQuery } from "./useSimpleApiQuery"
import { get } from "lodash-es"
import { Feature, Membership } from "@shared/interfaces"


export const useMembershipStatus = () => {
    const { data, isLoading } = useSimpleApiQuery("membership/status", {})
    return useMemo(() => {
        if (!data) {
            const membership = new Membership({})
            return {
                membership,
                features: [],
                isLoading,
            }
        }
        const record = get(data, "json.data") || {}
        // const { membership, features } = record
        const membership = new Membership(record.membership)
        const features = record.features.map((feature: any) => new Feature(feature))
        
        return { 
            membership, 
            features,
            isLoading
        }
    }, [data, isLoading])
}